import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import { color, wrapper1200 } from '../../../utils/style';
import Img from 'gatsby-image';

const Wrapper = styled('div')`
  margin: 30px 0;
  .wrapper1250 {
    max-width: 1288px;
    margin: 0 auto;
    h2 {
      padding: 0 30px;
    }
  }
  .flex {
    align-items: center;
    justify-content: center;
  }
  .col-2 {
    padding: 20px;
  }
  .download-image {
    width: 284px;
    max-width: 50%;
  }
  .download-image-text {
    flex: 1;
    padding-left: 30px;
    p {
      margin-bottom: 30px;
    }
    .button {
      display: block;
      margin: 15px 0;
    }
  }
`;

export default () => (
  <StaticQuery
    query={graphql`{
     desktop: file(relativePath: { eq: "download/download_computer.jpg" }) {
       childImageSharp {
         fluid(maxWidth: 284) {
          ...GatsbyImageSharpFluid_withWebp
         }
       }
     }
     mobile: file(relativePath: { eq: "download/download_mobile.jpg" }) {
       childImageSharp {
         fluid(maxWidth: 284) {
          ...GatsbyImageSharpFluid_withWebp
         }
       }
     }
    }`}
    render={data => (
      <Wrapper>
        <div className="wrapper1250">
          <h2>GCLUB Download Channels</h2>
          <div className="flex">
            <div className="col-2">
              <div className="flex">
                <div className="download-image">
                  {data.desktop.childImageSharp.fluid &&
                  <Img alt="ั้Download Computer" fluid={data.desktop.childImageSharp.fluid}/>
                  }
                </div>
                <div className="download-image-text">
                  <p>ดาวน์โหลดโปรแกรมติดตั้งลงคอมพิวเตอร์ สำหรับ windows เท่านั้น</p>
                  <div>
                    <a className="button gradient-button" target="_blank" rel="noopener noreferrer" aria-label="ดาวน์โหลด"
                       href="https://cdn.royalcdn.net/app/bacc168/gameroomTg.exe">ดาวน์โหลด 1</a>
                    <a className="button gradient-button" target="_blank" rel="noopener noreferrer" aria-label="ดาวน์โหลด"
                    href="https://cdn.rogcdn.net/app/bacc168/gameroomTg.exe">ดาวน์โหลด 2</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="flex">
                <div className="download-image">
                  {data.mobile.childImageSharp.fluid &&
                  <Img alt="ั้Download Mobile" fluid={data.mobile.childImageSharp.fluid}/>
                  }
                </div>
                <div className="download-image-text">
                  <p>ดาวน์โหลดโปรแกรมติดตั้งลงโทรศัพท์มือถือ</p>
                  <div>
                    <a className="button gradient-button" target="_blank" rel="noopener noreferrer" aria-label="ดาวน์โหลด"
                       href="https://app.bacc6666.com/RoyalOnline.apk">Android</a>
                    <a className="button gradient-button" target="_blank" rel="noopener noreferrer" aria-label="ดาวน์โหลด"
                    href="https://iosapp.bacc6666.com/">iOS</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    )}
  />
);
