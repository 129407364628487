import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import {
  wrapper1200,
  featuredWrapper,
  color,
  breakpoints,
  ContentStyles,
  ContentStylesExt
} from '../../utils/style';
import ContentWithFluidImages from '../../components/shared/ContentWithFluidImages';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import DownloadBlock from '../../components/Content/Download/DownloadTop';

const PageWrapper = styled('div')`
  .text-block {
    text-align: center;
  }
`;

const FeaturedWrapper = styled('div')`
  ${featuredWrapper}
  margin-bottom: 20px;
  .gatsby-image-wrapper {
    border-radius: 24px;
  }
  @media (max-width: ${breakpoints.md}) {
    .gatsby-image-wrapper {
      border-radius: 10px;
    }
  }
`;

const WrapperDesktop = styled('div')`
  ${wrapper1200}
`;

const ContentWrapper = styled('div')`
  ${wrapper1200}
  ${ContentStyles}
  ${ContentStylesExt}
  .dark-red-block {
    background: ${color.darkRed};
    padding: 30px 0;
    margin-top: 30px;
  }
  .step-card-text {
    background: ${color.darkRed};
    padding: 15px 30px;
  }
  .download-step {
    padding: 25px 30px;
    img {
      margin: 0;
      border-radius: 10px 10px 0 0;
    }
    p {
      margin-top: -3px;
      border-radius: 0 0 10px 10px;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    .download-step {
      flex-basis: 49%;
      padding: 0 10px;
    }
  }
  
  @media (max-width: ${breakpoints.sm}) {
    .download-step {
      flex-basis: 100%;
      padding: 0;
    }
  }
`;




const PageTemplate = ({ data, pageContext }) => {
  const currentPage = data.wordpressPage;
  const featuredMedia = currentPage.featured_media;
  const metaTitle = (currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_title)
    ? currentPage.yoast_meta.yoast_wpseo_title : currentPage.title + "- " + process.env.OWNER;
  const metaDesc = (currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_metadesc)
    ? currentPage.yoast_meta.yoast_wpseo_metadesc : '';
  const media = data.allWordpressWpMedia.edges;
  const breadcrumbs = pageContext.breadcrumbs;
  const page_title = pageContext.page_title;
  const socialImage = currentPage.featured_media?.localFile?.publicURL;


  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={currentPage.path}
        breadcrumbs={breadcrumbs}
        socialImage={socialImage}
      />
      <PageWrapper>
        <WrapperDesktop>
          <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title}/>
          {featuredMedia
          && featuredMedia.localFile
          && featuredMedia.localFile.childImageSharp
          && featuredMedia.localFile.childImageSharp.fluid &&
          <FeaturedWrapper>
            <Img fluid={featuredMedia.localFile.childImageSharp.fluid}
                 alt={(currentPage.featured_media.alt_text) ? currentPage.featured_media.alt_text : currentPage.title}/>
          </FeaturedWrapper>
          }
        <div className="text-block">
          <h1 dangerouslySetInnerHTML={{ __html: currentPage.title }}/>
          <p>Gclub Casino เป็นเว็บไซต์ที่มีการจัดการในระบบเดิมพันออนไลน์ได้อย่างสมบูรณ์ ยืนยันได้จากทุก ๆ ส่วนที่เปิดให้ใช้งานทั้งในส่วนของตัวเกม การติดต่อ และระบบของการทำธุรกรรมทางการเงิน ซึ่งมีความสะดวกสำหรับผู้ใช้งานแล้วก็ใช้งานง่ายอีกด้วย นอกจากนี้ทางคาสิโนยังมีระบบการถ่ายทอดสดโดยมีสัญญาณภาพจากปอยเปตที่คมชัดทั้งภาพและเสียงการันตีจากผู้ใช้คนไทย</p>
        </div>
        </WrapperDesktop>
        <DownloadBlock/>
        <ContentWrapper>
          {process.env.WP_REPLACE_CONTENT_IMAGES === '1' ?
            ContentWithFluidImages(currentPage.content, media) :
            <div dangerouslySetInnerHTML={{ __html: currentPage.content }}/>}
        </ContentWrapper>
      </PageWrapper>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query($id: String!, $related: [Int], $contentImages: [Int]) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        page_template
      }
      featured_media {
        alt_text
        localFile {
        publicURL
          childImageSharp {
            fluid(maxWidth: 1650) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }  
      } 
    }
    allWordpressPost(filter: {
      wordpress_id: {in: $related}, 
      acf: {post_template: {ne: "service_post_carousel"}}
    }, limit:4) {
        edges {
          node {
            slug
            title
            excerpt
            wordpress_id
            featured_media {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 350) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
    }
    placeholder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allWordpressWpMedia(filter: {wordpress_id: {in: $contentImages}}) {
      edges {
        node {
          wordpress_id
          alt_text  
          source_url
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1400) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
              }
            }
          }
        }
      }
    }
  }
`;
